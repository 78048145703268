<template>
    <div class="createQR">
        <img v-if="!downLoadName" :src="imgBase64" class="createQR-img download" >
        <a v-if="downLoadName" :href="imgBase64" :download="downLoadName" ><img :src="imgBase64" class="createQR-img download" ></a>
    </div>
</template>

<script>
import jrQrcode from 'jr-qrcode'
export default {
    name: 'createQR',
    props: {
        src:{
            type:String,
            default:"https://www.alltuu.com"
        },
        downLoadName: {
            type: String,
            default: ''
        },
        option: {
            type: Object,
            default: () => {},
        },
        isSubAdmin: {
          type: Boolean,
          default: false
        }
    },
    data() {
        return {
            imgBase64:null
        }
    },
    methods: {
    },
    mounted(){
        // 生成图片格式的二维码 插件jr-qrcode
        this.imgBase64 = jrQrcode.getQrBase64(this.src, this.option)
    },
    watch: {
        src(){
            this.imgBase64 = jrQrcode.getQrBase64(this.src, this.option)
            this.$emit('load', this.imgBase64)
        }
    }
}
</script>

<style lang="less">
.createQR{
    & a {
        display: inline-block;
        width: inherit;
        height: inherit;
    }
    &-img{
        width: inherit;
        height: inherit;
    }
}
</style>