import fetch from '../module/fetch'
import signature from '../module/signature'
import { apiUrl, domain, cloudStorageAPI, retouchBaseUrl, albumRevisionUrl, gsUrl, micrositeServerUrl, fssUrl, uploadUrl } from '../config/env'
let docWidth = document.documentElement.clientWidth;

/********************************************************
 *                                                      *
 *　                       页面数据                   　  *
 *                                                      *
 ********************************************************/
// 相册集跳转开关
export const settingPhotoSetAPI = (setIdN, jumpState, jumpUrl) => fetch(signature(albumRevisionUrl + '/rest/v3/album/set/jumpStateAndJumpUrl', {}, { setIdN, jumpState, jumpUrl }), { setIdN, jumpState, jumpUrl }, 'post');


//获取m端token
export const getMobileToken = () => fetch(signature(fssUrl + `/rest/v3/user/query/queryTokenByPcToken`, {}, {}), {}, 'post');

// 获取大活动状态
export const getActivityStatus = () => fetch(signature(fssUrl + `/rest/v3/query/index/queryActivityStatus`, {}, {}), {}, 'post');

//图片打星分数
export const starCount = (albumIdN, photoId, startLevel) => fetch(signature(fssUrl + '/rest/v3/set/photo/setPhotoStar', {}, { albumIdN, photoId, startLevel }), { albumIdN, photoId, startLevel }, 'post');

//问题反馈
export const getContact = (phone, content, type, backFrom=100010, albumIdN ='') => {
    let encodecontent = encodeURIComponent(content);
    return fetch(signature(fssUrl + `/rest/v3/user/base/addFeedback`, {}, { phone, content, type, backFrom, albumIdN }), { phone, content: encodecontent, type , backFrom, albumIdN}, 'post')
}


//生成直播视频推流链接
export const createVideoStream = (albumIdN, id, type) => fetch(signature(albumRevisionUrl + '/rest/v3/album/set/createAlbumLink', {}, { albumIdN, id, type }), { albumIdN, id, type }, 'post');


//视频直播结束接口
export const endLive = (albumIdN, id) => fetch(signature(albumRevisionUrl + '/rest/v3/album/set/videoRedirectStop', {}, { albumIdN, id }), { albumIdN, id }, 'post')

//删除视频场次
export const deleteLive = (albumIdN, id, pwd) => fetch(signature(albumRevisionUrl + `/rest/v3/album/set/banner/redirect/video/delete`, {}, { albumIdN, id, pwd }), { albumIdN, id, pwd }, 'post')

// 发起复制专题
export const copySpecial = (topicIdN) => fetch(signature(micrositeServerUrl + `/rest/v3/speical/topic/copySpecialTopic`, {}, { topicIdN }), { topicIdN }, 'post')

// 查询专题的复制状态
export const selectCopySpecial = (topicIdN) => fetch(signature(micrositeServerUrl + `/rest/v3/speical/topic/queryCopyState`, {}, { topicIdN }), { topicIdN }, 'post')


//获取案例页标签统计数据
export const getCasesTags = () => fetch(signature(albumRevisionUrl + '/rest/v3/index/query/queryCasePageData'))


//确认订单支付完成且回调处理完毕
export const getPayResult = (o) => fetch(signature(cloudStorageAPI + `/rest/v4/cloud/storage/order/ck/o${o}`, {}, { o }));

//摄影师简历分享
export const shareResume = (worksIds) => fetch(signature(apiUrl + '/rest/v3/pg/share/resume', {}, { worksIds }), { worksIds }, 'post');

// pc 主页获取新闻
export const getIndexNews = () => fetch(signature(fssUrl + `/rest/v3/query/index/queryNews`, {}, {}));

// pc 首页提交合作申请
export const postCooperation = ({ contact="", phone="", actId = "", content="", code = "", address="", backFrom }) => fetch(signature(albumRevisionUrl + '/rest/v3/demand/coopDemand/submit', {}, { contact, phone, content, code, backFrom }), { contact, phone, actId, content, code, address, backFrom }, 'post');

// pc 短信验证接口 使用ip限流
export const postSmsVer = ({ phone, vcodeType = 13, globalCode = "" }) => fetch(signature(fssUrl + '/rest/v3/user/base/sendValidCodeByPhone', {}, { phone, vcodeType, globalCode }), { phone, vcodeType, globalCode }, 'post');


//pc获取对应域名的页面信息
export const getUserPageInfo = (sub) => fetch(signature(fssUrl + `/rest/v3/user/query/allPcProDetail`, {}, {sub}), { sub }, 'post');


//PC 查看用户版本次数
export const getFlashPassState = (sub) => fetch(signature(albumRevisionUrl + `/rest/v3/user/takeFlashPassStatesN`, {}, {}));


//pc 相册改版 创建相册 不需要选择版本直接跳转至基本设置
export const createRevisionAlbum = (title, setId) => fetch(signature(albumRevisionUrl + '/rest/v3/album/base/create', {}, {title, setId}), {title, setId}, 'post');
//pc 相册改版  获取基本设置信息
export const getAlbumRevisionInfo = (albumIdN) => fetch(signature(albumRevisionUrl + '/rest/v3/album/query/baseSet', {}, {albumIdN}), {albumIdN}, 'post');


export const saveBaseSwitchSet = (albumIdN, typeState) => fetch(signature(albumRevisionUrl + '/rest/v3/album/set/baseSwitchSet', {}, {albumIdN, typeState}), {albumIdN, typeState}, 'post');
// pc 相册改版  基本设置保存设置
export const saveBasicAlbumSet = (albumIdN, title, albumCode, albumAddr, adate, addrDetail, style, uuid, GCJLocation, WGSLocation, isDefault, dsc, startDate, endDate, englishTitle, showNavigation, isSyncTitle) => fetch(signature(albumRevisionUrl + `/rest/v3/album/set/baseSet`, {}, { albumIdN, title, albumCode, albumAddr, adate, addrDetail, style, uuid, GCJLocation, WGSLocation, isDefault, dsc, startDate, endDate, englishTitle, showNavigation }), { albumIdN, title, albumCode, albumAddr, adate, addrDetail, style, uuid, GCJLocation, WGSLocation, isDefault, dsc, startDate, endDate, englishTitle, showNavigation, isSyncTitle }, 'post')




// pc 相册改版  顶部广告保存设置
export const saveTopBannerSet = (albumIdN, bannerTitle) => fetch(signature(albumRevisionUrl + `/rest/v3/album/set/bannerTitleSet`, {}, { albumIdN, bannerTitle }), { albumIdN, bannerTitle }, 'post')
// pc 相册改版 顶部宣传信息基本设置信息回显
export const getTopBannerInfo = (albumIdN) => fetch(signature(albumRevisionUrl + `/rest/v3/album/query/queryBannerBaseInfo`, {}, { albumIdN }), { albumIdN }, 'post')





// pc 相册改版 点赞气泡  保存设置
export const saveBubbleSet = (albumIdN, isGoodOpen, goodBubbleType, json) => fetch(signature(albumRevisionUrl + `/rest/v3/album/set/goodBubbleSet`, {}, { albumIdN, isGoodOpen, goodBubbleType, json }), { albumIdN, isGoodOpen, goodBubbleType, json }, 'post')
// pc 相册改版 相册查询
export const queryAlbums = (page, cnt, type, setId, title, startTime, endTime, sub, sort, labelIds, isTransfer = 0, isForm = 0) => fetch(signature(gsUrl + `/rest/v3/album/query/queryAlbumList`, {}, { page, cnt, type, setId, title, startTime, endTime, sub }), { page, cnt, type, setId, title, startTime, endTime, sub, sort, labelIds,isTransfer, isForm}, 'post')

// pc 相册改版 相册分享回显
export const getAlbumShareInfo = (albumIdN) => fetch(signature(albumRevisionUrl + `/rest/v3/album/query/albumShare`, {}, { albumIdN }), { albumIdN }, 'post')

// pc 相册改版 相册设置-自定义相册风格
export const newCreateAlbumCustom = (albumIdN, title, themeColor, themeVersion, topUuid, midUuid, endUuid, previewUuid, id, backgroundColor) => fetch(signature(albumRevisionUrl + `/rest/v3/album/set/customizeStyleSet`, {}, { albumIdN, title, themeColor, themeVersion, topUuid, midUuid, endUuid, previewUuid, id }), { albumIdN, title, themeColor, themeVersion, topUuid, midUuid, endUuid, previewUuid, id, backgroundColor }, 'post')
// pc 相册改版 相册设置-删除自定义相册风格
export const newDeleteAlbumCustom = (albumIdN, customStyleId) => fetch(signature(albumRevisionUrl + `/rest/v3/album/set/delCustomizeStyle`, {}, { albumIdN, customStyleId }), { albumIdN, customStyleId }, 'post')
// pc 相册改版 微信分享-回显数据
export const getWechatShareInfo = (albumIdN) => fetch(signature(albumRevisionUrl + `/rest/v3/album/query/queryWeChatShare`, {}, { albumIdN }), { albumIdN }, 'post')
// pc 相册改版 自定义广告-回显数据
export const getCustomAdInfo = (albumIdN) => fetch(signature(albumRevisionUrl + `/rest/v3/album/query/queryCustomAds`, {}, { albumIdN }), { albumIdN }, 'post')
// pc 相册改版 浏览设置-回显数据
export const getDefaultSetInfo = (albumIdN) => fetch(signature(albumRevisionUrl + `/rest/v3/album/query/queryAlbumBrowseSet`, {}, { albumIdN }), { albumIdN }, 'post')
// pc 相册改版 大图信息--回显数据
export const getBigPictureSetInfo = (albumIdN) => fetch(signature(albumRevisionUrl + `/rest/v3/album/query/queryAlbumBigPhoto`, {}, { albumIdN }), { albumIdN }, 'post')


export const confirmUploadCoverImg = (uuid) => fetch(signature(fssUrl + `/rest/v3/set/user/faceChange`, {}, { uuid }), { uuid }, 'post');
//pc 获取作品集接口

export const getAlbumWork = (sub) => fetch(signature(apiUrl + `/rest/v3/pro/work/sub${sub}`, {}, { sub }));

//pc 获取摄影师的资料
export const getPhotoGrapherDetail = (sub) => fetch(signature(apiUrl + `/rest/v3/pro/detail/sub${sub}`, {}, { sub }));

//PC用户登出接口　！！测试环境没用

export const pcloginOut = () => fetch(signature(fssUrl + `/rest/v3/set/user/logout`, {}, {}), { }, 'post');

//PC 登录验证
export const pcLoginVertify = (sub) => fetch(signature(fssUrl + `/rest/v3/user/base/validateUserByToken`, {}, { sub }), {sub}, 'post');

// 用户上报
export const userTrack = (d) => fetch(signature(albumRevisionUrl + `/rest/v3/fd/r`, {}, { d }), { d }, 'post');


//修改图片是否显示 老审核
export const setDisplay = (pc, display, albumIdN) => fetch(signature(apiUrl + `/rest/v3/auditing/displayMd`, {}, { pc, display, albumIdN }), { pc, display, albumIdN }, 'post');

//获取下载链接的md5密码 老打包
export const getDownloadPassword = (albumIdN) => fetch(signature(apiUrl + `/rest/v3/download/md5/pwd/get/albumIdN${albumIdN}`, {}, { albumIdN }));

//获取打包相册信息 老打包
export const getDownloadInfo = (albumIdN, pwd) => fetch(signature(apiUrl + `/rest/v3/download/album/package/info/albumIdN${albumIdN}/pwd${pwd}`, {}, { albumIdN, pwd }));

//获取下载链接详情（新增分页） 老打包
export const getDownloadDetailsNew = (albumIdN, pwd, pag, cnt, type) => fetch(signature(apiUrl + `/rest/v3/download/album/package/init/albumIdN${albumIdN}/pwd${pwd}/pag${pag}/cnt${cnt}/type${type}`, {}, { albumIdN, pwd, pag, cnt, type }));

//pc 下载页面　轮询 获取相册打包状态 老打包

export const getPackageState = (packageId) => fetch(signature(apiUrl + `/rest/v3/download/state/check/packageId${packageId}`, {}, { packageId }));

//pc 下载页面　打包 老打包

export const setPackageState = (packageId, pwd, type) => fetch(signature(apiUrl + `/rest/v3/download/state/do/packageId${packageId}/pwd${pwd}/type${type}`, {}, { packageId, pwd, type }));

//pc 下载页面　打包 l冷冻相册 老打包
export const setFreezePackageState = (packageId, pwd, type) => fetch(signature(apiUrl + `/rest/v3/download/state/freeze/do/packageId${packageId}/pwd${pwd}/type${type}`, {}, { packageId, pwd, type }));


//PC 案例页面通过标签获取案例信息
export const getTagsCaseInfo = (typeIds, labelIds, pageNum, pageSize) => fetch(signature(albumRevisionUrl + `/rest/v3/index/query/queryAlbumByTypeAndLabel`, {}, { typeIds, labelIds, pageNum, pageSize }), { typeIds, labelIds, pageNum, pageSize }, 'post');

//签约摄影师　基本信息提交

export const postSignPhotographerInfo = (obj) => {
        let goodList = [];
        Object.keys(obj.selectArea).forEach(key => {
            if (obj.selectArea[key] == true) {
                goodList.push(key)
            }
        })
        let name, age, wechatNum, model, lens, isThreeBigLens, beginWorkYear, goodAt, experience, hasDevice;
        name = obj.name;
        age = obj.age;
        wechatNum = obj.wechatID;
        model = obj.modelList.join("%&%");
        lens = obj.lensList.join("%&%");
        isThreeBigLens = Number(obj.hasLens);
        beginWorkYear = Number(obj.years);
        goodAt = goodList.join(";");
        experience = obj.details;
        hasDevice = obj.hasDevice;
        return fetch(signature(apiUrl + `/rest/v3/sign/pg/info/submit`, {}, { name, age, wechatNum, model, lens, isThreeBigLens, beginWorkYear, goodAt, experience, hasDevice }), { name, age, wechatNum: encodeURIComponent(wechatNum), model: encodeURIComponent(model), lens: encodeURIComponent(lens), isThreeBigLens, beginWorkYear, goodAt, experience: encodeURIComponent(experience), hasDevice }, 'post');
    }
    //签约摄影师　审核状态
export const getAuditingState = (type) => fetch(signature(apiUrl + `/rest/v3/sign/state/query`,{}, { type }), { type }, 'post');

// 新的按标题搜索相册 废弃
export const titleSearchAlbum = (title, cnt, pag) => fetch(signature(apiUrl + `/rest/v3/album/user/search/change/title${title}/cnt${cnt}/pag${pag}`, {}, {title, cnt, pag}));

//相册分类的显示与隐藏
export const albumClassShow = (albumIdN, sepIdN, display) => fetch(signature(albumRevisionUrl + `/rest/v3/sep/set/displaySepSetting`, {}, { albumIdN, sepIdN, display }), {albumIdN, sepIdN, display}, 'post');

//pc相册设置 获取常用菜单
export const getCommonMenu = () => fetch(signature(albumRevisionUrl + `/rest/v3/user/queryMenuCommonSetting`));

//pc相册设置　分类设置
export const postAlbumClassSetting = (albumIdN, sepStr) => {
        sepStr = JSON.stringify(sepStr)
        return fetch(signature(albumRevisionUrl + `/rest/v3/sep/set/sortSep`, {}, { albumIdN, sepStr }), { albumIdN, sepStr }, 'post');
    }

//获取设置水印信息和水印信息
export const getWaterInfo = (albumIdN, type = 0) => fetch(signature(albumRevisionUrl + `/rest/v3/album/query/queryWatermarkSetting/albumIdN${albumIdN}/type${type}`, {}, { albumIdN, type }));

//删除水印图片　
export const deleteWater = (id) => fetch(signature(fssUrl + `/rest/v3/set/photo/removeWatermark`, {}, {id}), { id }, 'post');

// 获取自定义菜单列表
export const getCustomNavList = (albumIdN) => fetch(signature(albumRevisionUrl + `/rest/v3/album/query/queryCustomMenuList`, {}, { albumIdN }), { albumIdN }, 'post');


// 创建自定义菜单
export const createCustomNavList = (albumIdN) => fetch(signature(albumRevisionUrl + `/rest/v3/album/set/customMenuCreate`, {}, { albumIdN }), { albumIdN }, 'post');

// 持久化自定义菜单图片路径

export const saveTmpPhoto = (albumIdN, menuId, json) => fetch(signature(albumRevisionUrl + `/rest/v3/album/set/saveCustomMenuPhoto`, {}, { albumIdN, menuId, json }), { albumIdN, menuId,  json}, 'post');


// 保存自定义菜单名称或外链
export const saveNavCustomName = (albumIdN, key, value, menuId) => fetch(signature(albumRevisionUrl + `/rest/v3/album/set/customMenuSetting`, {}, { albumIdN, key, value, menuId }), { albumIdN, key, value: value, menuId }, 'post');


// 自定义菜单开关设置
export const saveNavCustomSwitch = (albumIdN, menuId, isOpen) => fetch(signature(albumRevisionUrl + `/rest/v3/album/set/switchMenuSetting`, {}, { albumIdN, menuId, isOpen }), { albumIdN, menuId, isOpen }, 'post');

// 删除自定义菜单
export const deleteCustomNav = (albumIdN, menuId) => fetch(signature(albumRevisionUrl + `/rest/v3/album/set/deleteMenu`, {}, { albumIdN, menuId }), { albumIdN, menuId }, 'post');


// 保存自定义菜单排序
export const saveSortNav = (albumIdN, menuIds) => fetch(signature(albumRevisionUrl + `/rest/v3/album/set/sortAlbumCustomMenu`, {}, { albumIdN, menuIds }), { albumIdN, menuIds }, 'post');


// 保存富文本内容
export const saveRichTxtContent = (albumIdN, menuId, uuid) => fetch(signature(albumRevisionUrl + `/rest/v3/album/set/customMenuTxtConfirm`, {}, { albumIdN, menuId, uuid }), { albumIdN, menuId, uuid }, 'post');




// 统一的pc短信验证码登陆接口 腾讯新版防水墙
export const newSendSMS = (ticket, vc, vcodeType, phone, globalCode, albumIdN) => fetch(signature(fssUrl + `/rest/v3/user/base/sendTencentNewValidCode`, {}, { ticket, vc, vcodeType, phone, globalCode, albumIdN }), { ticket, vc, vcodeType, phone,globalCode, albumIdN }, 'post');



//检测最新的app包
export const getNewestApp = (type = 0) => fetch(signature(fssUrl + `/rest/v3/query/index/queryAppVersion`, {}, { type }), { type }, 'post');

//查询相册下所有分类 v3
export const searchAllClass = (albumIdN) => fetch(signature(gsUrl + `/rest/v3/album/query/queryAlbumSeperate`, {}, { albumIdN }), { albumIdN }, 'post');

// 标记图片信息 废弃
export const setImgInfo = (albumIdN, info, type, pc) => fetch(signature(apiUrl + `/rest/v3/pc/make/info`, {}, { albumIdN, info, type, pc }), { albumIdN, info, type, pc }, 'post');


//pc新相册编辑  设置封面 v3
export const setCoverLocalUpload = (albumIdN, photoId) => fetch(signature(albumRevisionUrl + `/rest/v3/album/set/settingCover`, {}, { photoId, albumIdN}), { photoId, albumIdN}, 'post');


//pc 友情链接获取

export const getFreindChain = (origin) => fetch(signature(fssUrl + `/rest/v3/query/index/queryAdLinkInfo`, {}, {origin}), { origin }, 'post');



//个人资料获取我的存储分页
export const getMyStorageList = (minTime, maxTime, origin, sizeMin, sizeMax, isEnd, sortType, pag, cnt, title) => fetch(signature(cloudStorageAPI + `/rest/v4/cloud/storage/user/album/cs/list`, {}, { minTime, maxTime, origin, sizeMin, sizeMax, isEnd, sortType, pag, cnt }), { minTime, maxTime, origin, sizeMin, sizeMax, isEnd, sortType, pag, cnt, title }, 'post');

// 获取已存储空间大小
export const getStorageSize = () => fetch(signature(cloudStorageAPI + `/rest/v4/cloud/storage/user/alltuu/size/cal`, {}, {}));


// 相册还原
export const restoreAlbum = (albumIdN) => fetch(signature(albumRevisionUrl + `/rest/v3/album/base/restoreAlbum`, {}, { albumIdN }), { albumIdN }, 'post');



// 回收站列表
export const getRecycleList = (page, cnt) => fetch(signature(albumRevisionUrl + `/rest/v3/album/query/queryRestoreAlbum`, {}, { page, cnt}), {page, cnt}, 'post');

export const wechatSubmitForm = (appName, name, nickname, phone, uuid) => fetch(signature(fssUrl + `/rest/v3/set/user/checkAuthFile`, {}, { appName, name, nickname, phone, uuid }), { appName, name, nickname, phone, uuid }, 'post');


//修图页面 下载照片
export const confirmDownloadRetouch = (albumIdN, photoId) => fetch(signature(retouchBaseUrl + `/rest/v3/retouch/photo/download`, {}, { albumIdN, photoId }), { albumIdN, photoId }, 'post');


//修图页面 评价摄影师提交 非官方相册
export const postPhgrapherEvaluate = (albumIdN, json) => fetch(signature(retouchBaseUrl + `/rest/v3/retouch/evaluate/submit`, {}, { albumIdN }), { albumIdN, json }, 'post');



//m ossApi
export const ossApi = a => fetch(a);

// 个人资料  简历页面  获取个人信息
export const getPotoGrapherInfo = (sub = '') => fetch(signature(apiUrl + `/rest/v3/pg/intro/detail/sub${sub}`, {}, { sub }));

// 创建作品集
export const createWorks = (type) => fetch(signature(apiUrl + `/rest/v3/pg/create/work`, {}, {type}), {type}, 'post');
// 编辑作品集
export const editWorks = ({ workId, photoNames, uploadOrigin, count }) => fetch(signature(apiUrl + `/rest/v3/pg/work`, {}, { workId, photoNames, uploadOrigin, count }), { workId, photoNames: encodeURIComponent(photoNames), uploadOrigin, count }, 'post');

// 上传作品集
export const deleteWorks = (workId) => fetch(signature(apiUrl + `/rest/v3/pg/del/work`, {}, { workId }), { workId }, 'post');
// 上传图片 获取Oss权限
export const upLoadImg = (contentId) => fetch(signature(apiUrl + `/rest/v3/sign/pro/upload/auth`, {}, { contentId }), { contentId }, 'post');

// 获取作品集详情
export const getWorksDeatil = (workId, code) => fetch(signature(apiUrl + `/rest/v3/pg/work/detail`, {}, { workId, code }), { workId, code }, 'post');
// 发布作品集
export const publishWorks = ({ workId, title, ids }) => fetch(signature(apiUrl + `/rest/v3/pg/work/publish/change`, {}, { workId, title, ids }), { workId, title: encodeURIComponent(title), ids }, 'post');

    // 个人资料页面 - 个人资料 - 获取用户信息
export const personDataGetInfo = () => fetch(signature(fssUrl + `/rest/v3/user/query/queryUserInfo`, {}, {}), {}, 'post')
    // 个人资料页面 - 安全中心 - 保存修改用户信息
export const saveEditUserInfo = (name, sex, addressee, addrCode, uuid) => fetch(signature(albumRevisionUrl + `/rest/v3/user/set/modifyUserInfo`, {}, { name, sex, addressee, addrCode, uuid }), { name, sex, addressee, addrCode, uuid },'post')
    // 个人资料页面 - 安全中心 - 保存修改用户信息
    export const saveEditPhotoModel = (model, lens, dsc) => fetch(signature(fssUrl + `/rest/v3/set/user/modifyUserProInfo`, {}, { model, lens, dsc }), {  model: encodeURIComponent(model), lens: encodeURIComponent(lens), dsc: encodeURIComponent(dsc) },'post')



    // 我的订单页面 - 获取订单列表
export const searchOrderList = (title, creatDateStart, creatDateEnd, offset, limit, type, orderType) => fetch(signature(fssUrl + `/rest/v3/user/query/getOrderList`, {}, { title, creatDateStart, creatDateEnd, offset, limit, type }), { title, creatDateStart, creatDateEnd, offset, limit, type, orderType }, 'post')
    // 我的订单页面 - 获取订单详情
export const getOrderDetile = (orderIdStr) => fetch(signature(fssUrl + `/rest/v3/pay/queryOrderDetail`, {}, { orderIdStr }), {orderIdStr}, 'post')
    // 我的订单页面 - 确定取消订单
export const cancelOrder = (orderIdStr) => fetch(signature(fssUrl + `/rest/v3/pay/retouchOrderCancel`, {}, { orderIdStr }), { orderIdStr }, 'post')

//PC 视频显示状态修改

export const changeVideoStatus = (albumIdN, videoId, display, type) => fetch(signature(fssUrl + `/rest/v3/set/video/setVideoDisplay`, {}, { albumIdN, videoId, display, type }), { albumIdN, videoId, display, type }, 'post');




// 获取ai 修图介绍页面的
export const aiDetailGetCase = (type) => fetch(signature(albumRevisionUrl + `/rest/v3/album/upload/getPhotoExampleList`, {}, {type}), {type});
// ai修图获取上传权限
export const getAiDeatilUploadAuth = (t) => fetch(signature(uploadUrl + `/rest/v4/cloud/photo/visitor/upload/auth/t${t}`, {}, {t}));
// 获取ai 修图介绍页面的
export const getAiDetailUploadLoading = (uuid) => fetch(signature(uploadUrl + `/rest/v4/cloud/photo/checkRetouchState`, {}, {uuid}), {uuid}, 'post');


// 视频直播获取提问接口
export const getIssueList = (albumIdN, loadFlag, sortFlag, pag, cnt) => fetch(signature(fssUrl + `/rest/v3/query/album/loadQuestion`, {}, {albumIdN, loadFlag, sortFlag, pag, cnt}), {albumIdN, loadFlag, sortFlag, pag, cnt}, 'post');
// 视频直播 显示/隐藏提问接口
export const displayIssue = (albumIdN, questionId, displayFlag) => fetch(signature(fssUrl + `/rest/v3/live/customization/displayQuestion`, {}, {albumIdN, questionId, displayFlag}), {albumIdN, questionId, displayFlag},  'post');

// 视频 直播回答接口
export const answerIssue = (albumIdN, questionId, answer, answerEn) => fetch(signature(fssUrl + `/rest/v3/live/customization/answerQuestion`, {}, {albumIdN, questionId, answer, answerEn}), {albumIdN, questionId, answer, answerEn}, 'post');

// 视频 翻译问题
export const setQuestionEn = (albumIdN, questionId, questionEn) => fetch(signature(fssUrl + `/rest/v3/live/customization/translateQuestionToEn`, {}, {albumIdN, questionId, questionEn}), {albumIdN, questionId, questionEn}, 'post');



// 获取微信分享配置
export const wechatConfigApi = url =>fetch(signature(fssUrl + `/rest/v3/wechat/base/queryWechatShareConfig`, {}, {url}), {url: encodeURIComponent(url)}, 'post');

// 获取用户会员过期信息 废弃
export const getMemberExpiration = () => fetch(signature(apiUrl + `/rest/v3/user/vip/state`, {}, {}));

// 设置相册菜单布局形式
export const settingALbumNavLayout = (albumIdN, menuShowStyle) => fetch(signature(albumRevisionUrl + '/rest/v3/album/set/menuStyleSetting', {}, { albumIdN, menuShowStyle }), { albumIdN, menuShowStyle }, 'post');


/********************************************************
 *                                                      *
 *　　　　　　               上传                       　 *
 *                                                      *
 *********************************************************/
//暂时的上传权限
export const getUploadAuthority = (t, a = 0) => fetch(signature(uploadUrl + `/rest/v4/cloud/photo/upload/auth/a${a}/t${t}`, {}, { t, a }));


/********************************************************
 *                                                      *
 *　　　                  json数据                    　  *
 *                                                      *
 ********************************************************/


export const getFetch = (url) => fetch(url);



/********************************************************
 *                                                      *
 *　　　                  SEO相关配置文件                  *
 *                                                      *
 ********************************************************/


export const getSeoBeeConfig = () => fetch(`https://spu.${domain}.com/default/json/bee.json`, {});

/********************************************************
 *                                                      *
 *　　　                  问答                *
 *                                                      *
 ********************************************************/
// 置顶
export const setTopQuestion = (albumIdN, questionId, topFlag) => fetch(signature(fssUrl + '/rest/v3/live/customization/topQuestion', {}, { albumIdN, questionId, topFlag }), { albumIdN, questionId, topFlag }, 'post');

/********************************************************
 *                                                      *
 *　　　                  相册解冻                *
 *                                                      *
 ********************************************************/
// 解冻相册
export const checkAlbumStatus = (albumIdN) => fetch(signature(apiUrl + '/rest/v3/check/archive/status', {}, { albumIdN}), { albumIdN }, 'post');

// 冷冻相册 测试用
export const freezePhoto = (pIds) => fetch(signature(cloudStorageAPI + '/rest/v4/photoFreeze/restorePhoto', {}, { pIds}), { pIds }, 'post');

// 查询解冻状态
export const checkFreezeStatus = (pIds) => fetch(signature(cloudStorageAPI + '/rest/v4/photoFreeze/restorePhotoComfirm', {}, { pIds}), { pIds }, 'post');

// 获取专题模版
export const specialTemplateList = (labelId, page, cnt) => fetch(signature(micrositeServerUrl + `/rest/v3/speical/topic/findSpecialTopicFormatNew`, {}, {labelId, page, cnt}) + `?labelId=${labelId}&page=${page}&cnt=${cnt}`);
// 获取模版类型枚举
export const specialTemplateTypeList = () => fetch(signature(albumRevisionUrl + `/rest/v3/speical/topic/findSpecialTopicLabels`, {}, {}), {}, 'post');

// 新验证码登陆
export const newVerificationLogin = (name, sms) => fetch(signature(albumRevisionUrl + `/rest/v3/user/base/loginBySms/`, {}, { name, sms }), { name, sms }, 'post');

// 新重置密码
export const newResetPassword = (phone, sms , pwd) => fetch(signature(albumRevisionUrl + `/rest/v3/user/base/findPwd`, {}, { phone, sms , pwd}), { phone, sms , pwd}, 'post');

// 新 个人资料页面 - 安全中心 - 修改密码
export const newEditPwd = ({ oldPwd, newPwd }) => fetch(signature(albumRevisionUrl + `/rest/v3/user/base/modifyPwdV3`, {}, { oldPwd, newPwd }), { oldPwd: encodeURIComponent(oldPwd), newPwd: encodeURIComponent(newPwd) }, 'post')

// 新用户登录接口
export const newUserLogin = (name, pwd) => fetch(signature(albumRevisionUrl + `/rest/v3/user/base/loginNew`, {}, { name, pwd }), { name, pwd }, 'post')

// 新删除相册
export const newDeleteStorageAlbum = (ids) => fetch(signature(albumRevisionUrl + `/rest/v3/album/base/batchDeleteAlbum`, {}, { ids }), { ids }, 'post');
